import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import auth from "./modules/auth";
import goals from "./modules/goals";
import flRatio from "./modules/fl-ratio";
import user from "./modules/user";
import bankBalance from "./modules/bank-balance";
import notification from "./modules/notification/notification";
import salesCosts from "./modules/sales-costs";
import contact from "./modules/contact";
import profile from "./modules/profile";
import settings from "./modules/settings";
import linkPosts from "./modules/notification/link-post";
import faq from "./modules/faq";

Vue.use(Vuex);

const getDefaultState = () => {
  return {
    modalVisible: false,
    modalComponent: null,
    modalTitle: "Dialog Title",
    apiProcessing: false,
    alert: false,
    alertText: "",
    alertSuccess: false,
    errors: {}
  };
};

export default new Vuex.Store({
  state: getDefaultState(),

  mutations: {
    showModal(state, { title, component }) {
      state.modalVisible = true;
      state.modalComponent = component;
      title !== undefined
        ? (state.modalTitle = title)
        : (state.modalTitle = getDefaultState().modalTitle);
    },
    showAlert(state, { text, successStatus }) {
      state.alert = true;
      state.alertText = text;
      state.alertSuccess = successStatus;
    },
    hideAlert(state) {
      state.alert = false;
    },
    hideModal(state) {
      state.modalVisible = false;
      state.modalComponent = null;
    },
    setApiProcessing(state, payload) {
      state.apiProcessing = payload;
    },
    setFormErrors(state, payload) {
      state.errors = payload;
    },
    clearFormErrors(state) {
      state.errors = {};
    }
  },

  actions: {
    API_PROCESSING({ commit }, payload) {
      commit("setApiProcessing", payload);
    },
    SHOW_ALERT({ commit }, payload) {
      commit("showAlert", payload);
    }
  },

  getters: {
    getApiProcessingStatus: state => state.apiProcessing,
    getAlertStatus: state => state.alert,
    getAlertText: state => state.alertText,
    getAlertSuccess: state => state.alertSuccess,
    getErrors: state => state.errors,
    getModalTitle: state => state.modalTitle,
    getModalComponent: state => state.modalComponent
  },

  modules: {
    auth,
    goals,
    flRatio,
    user,
    bankBalance,
    notification,
    salesCosts,
    contact,
    profile,
    settings,
    linkPosts,
    faq
  },

  plugins: [createPersistedState()]
});
