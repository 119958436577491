<template>
  <v-navigation-drawer
    :value="shown"
    fixed
    width="100%"
    @input="updateShown"
    class="nav-menu"
  >
    <v-toolbar elevation="0" class="mt-4 mb-8 mx-4">
      <v-toolbar-title>
        <div>
          <h6 class="app-title font-weight-bold text-heading-3">
            redish Cross Point
          </h6>
          <h4 class="font-weight-bold text-heading-4">
            {{ companyName }} /
            {{ lastName }}
            <small>さん</small>
          </h4>
        </div>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn icon @click="_shown = false">
        <v-icon color="red">mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-list class="mx-8">
      <v-divider></v-divider>
      <template v-for="(item, index) in menu">
        <v-list-item
          v-if="!item.submenu"
          v-bind:to="
            item.submenu === false && item.text !== 'Logout' ? item.to : ''
          "
          :key="index"
          @click="handler(item)"
        >
          <v-list-item-title
            class="font-weight-medium main--text text-heading-4"
          >
            <span class="mr-2">{{ item.text }}</span>

            <v-chip
              class="notification-badge"
              color="red"
              text-color="white"
              x-small
              v-if="item.hasBadge && getNotificationUnreadCount > 0"
            >
              {{ getNotificationUnreadCount }}
            </v-chip>
          </v-list-item-title>
        </v-list-item>

        <v-list-group
          v-else
          :value="item.prominent"
          :key="index"
          append-icon=""
        >
          <template v-slot:appendIcon>
            <v-icon size="15">$grayDownArrow</v-icon>
          </template>

          <template v-slot:activator>
            <v-list-item-title
              class="font-weight-medium main--text text-heading-4"
            >
              {{ item.text }}
            </v-list-item-title>
          </template>

          <template v-for="(subItem, subIndex) in item.children">
            <v-divider :key="'subItemDivider-' + subIndex"></v-divider>
            <v-list-item
              class="pl-8"
              :key="subIndex"
              v-bind:to="subItem.newTab === undefined ? subItem.to : ''"
              :href="subItem.newTab !== undefined ? subItem.to : ''"
              :target="subItem.newTab ? '_blank' : ''"
              @click="_shown = false"
              exact
            >
              <v-list-item-title
                class="font-weight-medium main--text text-heading-3"
              >
                <v-icon size="10" class="mr-2">$rightArrow</v-icon>
                {{ subItem.text }}
              </v-list-item-title>
            </v-list-item>
          </template>

          <template v-if="item.hasTos">
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-title>
                <v-row>
                  <v-col cols="6">
                    <v-btn
                      class="footer-item-text"
                      to="/privacy-policy"
                      text
                      plain
                      x-small
                      @click="_shown = false"
                    >
                      {{ $t("privacy_policy") }}
                    </v-btn>
                  </v-col>
                  <v-col cols="6">
                    <v-btn
                      class="footer-item-text"
                      to="/terms-conditions"
                      text
                      plain
                      x-small
                      @click="_shown = false"
                    >
                      {{ $t("terms_of_service") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-list-group>

        <v-divider :key="'divider-' + index"></v-divider>
      </template>
    </v-list>

    <v-flex class="text-center mt-4">
      <v-col cols="12">
        <v-btn @click="_shown = false" text>{{ $t("close") }}</v-btn>
      </v-col>
      <v-col class="mt-8 pb-0" cols="12">
        <v-btn disabled depressed x-small class="text-lowercase font-italic">
          {{ version }}
        </v-btn>
      </v-col>
      <v-col cols="12">
        <small>© redish Co., Ltd. {{ copyrightYear }}</small>
      </v-col>
    </v-flex>
  </v-navigation-drawer>
</template>

<script>
import dayjs from "@/plugins/dayjs";
import { mapGetters } from "vuex";
export default {
  name: "ApplicationNavMenu",
  props: {
    shown: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    if (
      Notification.permission == "denied" ||
      Notification.permission == "default" ||
      this.isIOSDevice()
    ) {
      this.updateNotificationPreference(0); // Email will be sent
    } else {
      this.updateNotificationPreference(1); // Notification will be sent
    }
    // The User ID is necessary for push notification request from index.html
    sessionStorage.setItem("user_id", this.user?.id);
  },
  created() {
    if (this.user) {
      this.$store.dispatch("NOTIFICATION_UNREAD_COUNT_GET");
    }
  },
  computed: {
    _shown: {
      get: function() {
        return this.shown;
      },
      set: function(newValue) {
        this.$emit("update:shown", newValue);
      }
    },
    copyrightYear() {
      return `2017 - ${dayjs().format("YYYY")}`;
    },
    companyName() {
      return this.user?.company?.display_name;
    },
    lastName() {
      return this.user?.last_name;
    },
    ...mapGetters(["user", "getNotificationUnreadCount"])
  },
  data() {
    return {
      version: "β ver. 1.0",
      notificationCount: 3,
      menu: [
        {
          text: this.$t("sidebar_top"),
          to: "/",
          hasTos: false,
          hasBadge: false,
          submenu: false,
          prominent: true,
          children: []
        },
        {
          text: this.$t("sidebar_menu"),
          to: "/",
          hasTos: false,
          hasBadge: false,
          submenu: true,
          prominent: true,
          children: [
            {
              text: this.$t("sidebar_menu_sales_target"),
              to: "/goals"
            },
            {
              text: this.$t("sidebar_menu_fl_ratio_report"),
              to: "/fl-ratio"
            },
            {
              text: this.$t("sidebar_menu_deposit_balance"),
              to: "/balance"
            },
            {
              text: this.$t("sidebar_menu_sales_and_costs_monthly"),
              to: "/sales-costs"
            },
            {
              text: this.$t("sidebar_menu_sales_and_costs_daily"),
              to: "/sales-costs/daily"
            },
            {
              text: this.$t("sidebar_menu_sales_and_costs_food_cost"),
              to: "/sales-costs/daily/food"
            }
          ]
        },
        {
          text: this.$t("sidebar_notifications"),
          to: "/notifications",
          hasTos: false,
          hasBadge: true,
          submenu: false,
          prominent: true,
          children: []
        },
        {
          text: this.$t("sidebar_link_posts"),
          to: "/link-posts",
          hasTos: false,
          hasBadge: false,
          submenu: false,
          prominent: true,
          children: []
        },
        {
          text: this.$t("sidebar_settings"),
          to: "/",
          hasTos: true,
          hasBadge: false,
          submenu: true,
          prominent: false,
          children: [
            {
              text: this.$t("sidebar_settings_change_profile"),
              to: "/profile/edit"
            },
            {
              text: this.$t("sidebar_settings_set_target_sales"),
              to: "/goals/settings"
            },
            {
              text: this.$t("sidebar_settings_fl_ratio_settings"),
              to: "/settings/fl-ratio"
            },
            {
              text: this.$t("sidebar_settings_notification_settings"),
              to: "/profile/settings"
            },
            {
              text: this.$t("sidebar_settings_faq"),
              to: "/faq"
            },
            {
              text: this.$t("sidebar_settings_consult"),
              to: "/contact"
            },
            {
              text: this.$t("sidebar_settings_consult_line"),
              to: "https://lin.ee/qmYLS7j",
              newTab: true
            },
            {
              text: this.$t("sidebar_settings_change_password"),
              to: "/change-password"
            }
          ]
        },
        {
          text: this.$t("sidebar_logout"),
          to: "/logout",
          hasTos: false,
          hasBadge: false,
          submenu: false,
          prominent: false
        }
      ]
    };
  },
  methods: {
    updateNotificationPreference(isAllowed) {
      if (this.user) {
        this.$store.dispatch("SETTINGS_PREFERENCES_UPDATE", {
          preferences: [{ name: "notification.browser", value: isAllowed }],
          hide_success_message: true
        });
      }
    },

    isIOSDevice() {
      return (
        !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
      );
    },

    updateShown(event) {
      this.$emit("update:shown", event);
    },

    handler(item) {
      if (item.to === "/logout") {
        this.logout();
      }

      this._shown = false;
    },

    logout() {
      this.$store.dispatch("AUTH_LOGOUT");
    }
  }
};
</script>

<style scoped>
.app-title {
  color: #949494;
}
.v-navigation-drawer {
  will-change: initial;
}
.footer-item-text {
  color: #b4b4b4 !important;
  font-size: 10px !important;
}
.nav-menu {
  z-index: 99 !important;
}

.notification-badge {
  padding: 0 5px !important;
}
</style>
