import axios from "@/services/api";
import {
  SALES_COSTS_REQUEST,
  SALES_COSTS_DAILY_GET,
  SALES_COSTS_DAILY_GET_SUCCESS,
  SALES_COSTS_DAILY_GET_ERROR,
  SALES_COSTS_DAILY_FOOD_GET,
  SALES_COSTS_DAILY_FOOD_GET_SUCCESS,
  SALES_COSTS_DAILY_FOOD_GET_ERROR,
  SALES_COSTS_MONTHLY_GET,
  SALES_COSTS_MONTHLY_GET_SUCCESS,
  SALES_COSTS_MONTHLY_GET_ERROR
} from "@/store/actions/sales-costs";

function initialState() {
  return {
    monthly: [],
    daily: [],
    daily_food: []
  };
}

const state = initialState();

const getters = {
  getSalesCostsMonthlyData: state => state.monthly,
  getSalesCostsDailyData: state => state.daily,
  getSalesCostsDailyFoodData: state => state.daily_food
};

const actions = {
  [SALES_COSTS_MONTHLY_GET]: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      commit("SALES_COSTS_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get(`/user/reports/monthly-deals`)
        .then(response => {
          commit("SALES_COSTS_MONTHLY_GET_SUCCESS", response.data.data);

          resolve(response);
        })
        .catch(error => {
          commit("SALES_COSTS_MONTHLY_GET_ERROR");

          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },

  [SALES_COSTS_DAILY_GET]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("SALES_COSTS_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get(`/user/reports/daily-deals`, {
          params: params
        })
        .then(response => {
          commit("SALES_COSTS_DAILY_GET_SUCCESS", response.data.data);

          resolve(response);
        })
        .catch(error => {
          commit("SALES_COSTS_DAILY_GET_ERROR");

          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },

  [SALES_COSTS_DAILY_FOOD_GET]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("SALES_COSTS_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get(`/user/reports/daily-deals/food`, {
          params: params
        })
        .then(response => {
          commit("SALES_COSTS_DAILY_FOOD_GET_SUCCESS", response.data.data);

          resolve(response);
        })
        .catch(error => {
          commit("SALES_COSTS_DAILY_FOOD_GET_ERROR");

          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  }
};

const mutations = {
  [SALES_COSTS_REQUEST]: state => {
    state.status = "loading";
  },

  [SALES_COSTS_MONTHLY_GET_SUCCESS]: (state, params) => {
    state.status = "success";
    state.monthly = params;
  },

  [SALES_COSTS_MONTHLY_GET_ERROR]: state => {
    state.status = "error";
  },

  [SALES_COSTS_DAILY_GET_SUCCESS]: (state, params) => {
    state.status = "success";
    state.daily = params;
  },

  [SALES_COSTS_DAILY_GET_ERROR]: state => {
    state.status = "error";
  },

  [SALES_COSTS_DAILY_FOOD_GET_SUCCESS]: (state, params) => {
    state.status = "success";
    state.daily_food = params;
  },

  [SALES_COSTS_DAILY_FOOD_GET_ERROR]: state => {
    state.status = "error";
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
