import dayjs from "dayjs";
import updateLocale from "dayjs/plugin/updateLocale";
import "dayjs/locale/ja";

dayjs.extend(updateLocale);

dayjs.updateLocale("ja", {
  weekdays: ["日", "月", "火", "水", "木", "金", "土"]
});

dayjs.locale("ja");

export default dayjs;
