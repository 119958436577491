import Vue from "vue";
import i18n from "@/plugins/i18n";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import {
  required,
  email,
  max,
  size,
  max_value,
  min_value,
  confirmed
} from "vee-validate/dist/rules.umd";

extend("required", {
  ...required,
  message: i18n.t("field_required_message")
});

extend("max", {
  ...max,
  message: i18n.t("field_max_message")
});

extend("size", {
  ...size,
  message: i18n.t("field_size_message")
});

extend("email", {
  ...email,
  message: i18n.t("field_email_message")
});

extend("max_fl_ratio", {
  ...max_value,
  message: i18n.t("field_max_fl_ratio_message")
});

extend("min_value", {
  ...min_value,
  message: i18n.t("field_min_value_message")
});

extend("password_confirmed", {
  ...confirmed,
  message: i18n.t("field_password_confirmed_message")
});

extend("verify_password", {
  message: `以下のルールに従ってください`,
  validate: value => {
    var strongRegex = new RegExp(
      "(?=.{8,})((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])|(?=.*\\d)(?=.*[a-zA-Z])(?=.*[\\W_])|(?=.*[a-z])(?=.*[A-Z])(?=.*[\\W_])).*"
    );
    let length = value.length;
    return strongRegex.test(value) && length >= 8 && length <= 20;
  }
});

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
