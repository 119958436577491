export const SALES_COSTS_REQUEST = "SALES_COSTS_REQUEST";
export const SALES_COSTS_MONTHLY_GET = "SALES_COSTS_MONTHLY_GET";
export const SALES_COSTS_MONTHLY_GET_SUCCESS =
  "SALES_COSTS_MONTHLY_GET_SUCCESS";
export const SALES_COSTS_MONTHLY_GET_ERROR = "SALES_COSTS_MONTHLY_GET_ERROR";
export const SALES_COSTS_DAILY_GET = "SALES_COSTS_DAILY_GET";
export const SALES_COSTS_DAILY_GET_SUCCESS = "SALES_COSTS_DAILY_GET_SUCCESS";
export const SALES_COSTS_DAILY_GET_ERROR = "SALES_COSTS_DAILY_GET_ERROR";
export const SALES_COSTS_DAILY_FOOD_GET = "SALES_COSTS_DAILY_FOOD_GET";
export const SALES_COSTS_DAILY_FOOD_GET_SUCCESS =
  "SALES_COSTS_DAILY_FOOD_GET_SUCCESS";
export const SALES_COSTS_DAILY_FOOD_GET_ERROR =
  "SALES_COSTS_DAILY_FOOD_GET_ERROR";
