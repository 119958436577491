<template>
  <div id="app">
    <v-app :style="{ background: $vuetify.theme.themes[theme].background }">
      <v-main>
        <ModalDialog />

        <v-badge
          v-if="isLoggedIn"
          class="nav-menu-badge"
          :color="getNotificationUnreadCount > 0 ? 'red' : 'transparent'"
          :content="
            getNotificationUnreadCount > 0 ? getNotificationUnreadCount : ''
          "
        >
          <v-btn
            class="nav-menu-button"
            fab
            x-small
            fixed
            @click="drawer = !drawer"
          >
            <v-icon color="primary" size="20">mdi-menu</v-icon>
          </v-btn>
        </v-badge>

        <ApplicationNavMenu :shown.sync="drawer" />

        <ApplicationBar v-if="isLoggedIn" />
        <v-progress-linear
          color="secondary"
          height="5"
          indeterminate
          :active="getApiProcessingStatus"
        ></v-progress-linear>

        <v-container :class="!isLoggedIn ? 'fill-height' : ''">
          <v-row align="center" justify="center">
            <v-col
              cols="12"
              :class="$route.name === 'NotificationDetail' ? 'px-0' : ''"
            >
              <router-view
                :key="$route.fullPath"
                style="max-width: 375px"
              ></router-view>
            </v-col>
          </v-row>
        </v-container>

        <v-snackbar
          v-model="alertStatus"
          :color="getAlertSuccess ? 'success' : ''"
          :timeout="15000"
        >
          {{ getAlertText }}

          <template v-slot:action="{ attrs }">
            <v-btn
              color="white"
              text
              v-bind="attrs"
              @click="alertStatus = false"
            >
              {{ $t("close") }}
            </v-btn>
          </template>
        </v-snackbar>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import ApplicationBar from "@/components/layout/ApplicationBar";
import ApplicationNavMenu from "@/components/layout/ApplicationNavMenu";
import ModalDialog from "@/components/ui/ModalDialog";
import { mapGetters } from "vuex";

export default {
  components: { ModalDialog, ApplicationNavMenu, ApplicationBar },
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    alertStatus: {
      get: function() {
        return this.$store.getters.getAlertStatus;
      },
      set: function() {
        this.$store.commit("hideAlert");
      }
    },
    ...mapGetters([
      "getAlertText",
      "getApiProcessingStatus",
      "getAlertSuccess",
      "getNotificationUnreadCount"
    ])
  },
  data() {
    return {
      drawer: false
    };
  },
  methods: {
    closeAlert() {
      this.$store.commit("hideAlert");
    }
  }
};
</script>

<style scoped>
::v-deep .v-badge__badge {
  display: flex;
  justify-content: center;
}
.nav-menu-badge {
  position: fixed;
  right: 42px;
  top: 8px;
  z-index: 4;
}
.nav-menu-badge.v-badge >>> .v-badge__wrapper {
  top: 5px !important;
  z-index: 5;
  left: 24px !important;
}
.nav-menu-badge.v-badge >>> .v-badge__wrapper .v-badge__badge {
  height: 15px !important;
  min-width: 15px !important;
  width: 15px !important;
  line-height: 0.7 !important;
  padding: 4px 4px !important;
  font-size: 10px !important;
}

.nav-menu-button {
  background-color: rgba(255, 255, 255, 0.65) !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2) !important;
}
</style>
