import Vue from "vue";
import Vuetify from "vuetify/lib";
import "vuetify/dist/vuetify.min.css";
import QuestionMarkIcon from "@/components/icons/QuestionMarkIcon";
import PlayIcon from "@/components/icons/PlayIcon";
import FlagIcon from "@/components/icons/FlagIcon";
import RightArrowIcon from "@/components/icons/RightArrowIcon";
import NewTabIcon from "@/components/icons/NewTabIcon";
import BlueDownArrowIcon from "@/components/icons/BlueDownArrowIcon";
import ReloadIcon from "@/components/icons/ReloadIcon";
import GrayRightArrowIcon from "@/components/icons/GrayRightArrowIcon";
import BackButtonIcon from "@/components/icons/BackButtonIcon";
import GrayDownArrowIcon from "@/components/icons/GrayDownArrowIcon";

Vue.use(Vuetify);

const opts = {
  theme: {
    themes: {
      light: {
        primary: "#495BC4",
        secondary: "#0094FF",
        accent: "#69E3FF",
        background: "#EAEDF0"
      }
    }
  },
  icons: {
    values: {
      question: {
        component: QuestionMarkIcon
      },
      play: {
        component: PlayIcon
      },
      flag: {
        component: FlagIcon
      },
      rightArrow: {
        component: RightArrowIcon
      },
      grayRightArrow: {
        component: GrayRightArrowIcon
      },
      newTab: {
        component: NewTabIcon
      },
      blueDownArrow: {
        component: BlueDownArrowIcon
      },
      grayDownArrow: {
        component: GrayDownArrowIcon
      },
      reload: {
        component: ReloadIcon
      },
      backButton: {
        component: BackButtonIcon
      }
    }
  }
};

export default new Vuetify(opts);
