import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import(/* webpackChunkName: "home" */ "../views/Home")
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/auth/Login"),
    meta: {
      public: true
    }
  },
  {
    path: "/forgot-password",
    name: "forgot_password",
    component: () =>
      import(
        /* webpackChunkName: "forgot-password" */ "../views/auth/ForgotPassword"
      ),
    meta: {
      public: true
    }
  },
  {
    path: "/lp",
    name: "lp",
    component: () => import(/* webpackChunkName: "lp" */ "../views/lp"),
    meta: {
      public: true
    }
  },
  {
    path: "/register",
    name: "register",
    component: () =>
      import(/* webpackChunkName: "register" */ "../views/auth/Register"),
    meta: {
      public: true
    }
  },
  {
    path: "/reset-password/:token",
    name: "reset_password",
    component: () =>
      import(
        /* webpackChunkName: "reset-password" */ "../views/auth/ResetPassword"
      ),
    meta: {
      public: true
    }
  },
  {
    path: "/services",
    name: "Services",
    component: () =>
      import(/* webpackChunkName: "services" */ "../views/Pages/Services")
  },
  {
    path: "/profile",
    name: "profile",
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/user/Profile")
  },
  {
    path: "/profile/settings",
    name: "settings",
    component: () =>
      import(/* webpackChunkName: "setting" */ "../views/user/Setting")
  },
  {
    path: "/profile/edit",
    name: "edit_profile",
    component: () =>
      import(/* webpackChunkName: "profile-edit" */ "../views/user/EditProfile")
  },
  {
    path: "/contact",
    name: "contact_form",
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/ContactForm")
  },
  {
    path: "/change-password",
    name: "change_password",
    component: () =>
      import(
        /* webpackChunkName: "change_password" */ "../views/user/ChangePassword"
      )
  },
  {
    path: "/faq",
    name: "faq",
    component: () => import(/* webpackChunkName: "faq" */ "../views/FAQ")
  },
  {
    path: "/privacy-policy",
    name: "privacy_policy",
    component: () =>
      import(/* webpackChunkName: "privacy-policy" */ "../views/PrivacyPolicy"),
    meta: {
      allAccess: true
    }
  },
  {
    path: "/terms-conditions",
    name: "terms_and_conditions",
    component: () =>
      import(
        /* webpackChunkName: "terms-conditions" */ "../views/TermsAndConditions"
      ),
    meta: {
      allAccess: true
    }
  },
  {
    path: "/goals",
    name: "goals",
    component: () =>
      import(/* webpackChunkName: "goals-main" */ "../views/goals/GoalsMain")
  },
  {
    path: "/goals/settings",
    name: "goals_settings",
    component: () =>
      import(
        /* webpackChunkName: "goals-settings" */ "../views/goals/GoalsSetting"
      )
  },
  {
    path: "/fl-ratio",
    name: "fl_ratio",
    component: () =>
      import(/* webpackChunkName: "fl-ratio" */ "../views/FLRatio")
  },
  {
    path: "/balance",
    name: "bank_balance",
    component: () =>
      import(/* webpackChunkName: "bank-balance" */ "../views/BankBalance")
  },
  {
    path: "/sales-costs",
    name: "monthly_sales_and_costs",
    component: () =>
      import(
        /* webpackChunkName: "monthly-sales-costs" */ "../views/sales-costs/MonthlySalesAndCosts.vue"
      )
  },
  {
    path: "/sales-costs/daily",
    name: "daily_sales_and_costs",
    component: () =>
      import(
        /* webpackChunkName: "daily-sales-costs" */ "../views/sales-costs/DailySalesAndCosts.vue"
      )
  },
  {
    path: "/sales-costs/daily/food",
    name: "daily_food_sales_and_costs",
    component: () =>
      import(
        /* webpackChunkName: "daily-food-sales-costs" */ "../views/sales-costs/DailyFoodCost.vue"
      )
  },
  {
    path: "/notifications",
    name: "notifications_list",
    component: () =>
      import(
        /* webpackChunkName: "notifications" */ "../views/Notifications.vue"
      )
  },
  {
    path: "/notifications/category/:category_id",
    name: "notifications_list_category",
    component: () =>
      import(
        /* webpackChunkName: "notifications" */ "../views/Notifications.vue"
      )
  },
  {
    path: "/notifications/tag/:tag_id",
    name: "notifications_list_tag",
    component: () =>
      import(
        /* webpackChunkName: "notifications" */ "../views/Notifications.vue"
      )
  },
  {
    path: "/notifications/:id",
    name: "notification_detail",
    component: () =>
      import(
        /* webpackChunkName: "notification-detail" */ "../components/layout/notifications/NotificationDetail.vue"
      )
  },
  {
    path: "/link-posts",
    name: "link_posts",
    component: () =>
      import(/* webpackChunkName: "link_posts" */ "../views/LinkPosts.vue")
  },
  {
    path: "/settings/fl-ratio",
    name: "fl_ratio_setting",
    component: () =>
      import(/* webpackChunkName: "fl-ratio" */ "../views/settings/FLRatio")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  let previousUrl = window.location.host + from.fullPath;
  localStorage.setItem("previousUrl", previousUrl);

  if (to.meta.allAccess) return next();

  if (store.state.modalVisible) store.commit("hideModal");

  const authRequired = !to.matched.some(record => record.meta.public);
  const loggedIn = store.getters.token;
  store.commit("clearFormErrors");

  if (loggedIn) store.dispatch("PROFILE_GET");

  if (authRequired && !loggedIn) {
    next({
      path: "/login",
      query: { redirect: to.fullPath }
    });
  } else if (!authRequired && loggedIn) {
    next("/");
  }
  next();
});

export default router;
