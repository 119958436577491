import axios from "@/services/api";
import {
  BANK_BALANCE_REQUEST,
  BANK_BALANCE_GET,
  BANK_BALANCE_GET_SUCCESS,
  BANK_BALANCE_GET_ERROR,
  BANK_BALANCE_RESET,
  BANK_ACCOUNTS_GET,
  BANK_ACCOUNTS_GET_SUCCESS,
  BANK_ACCOUNTS_GET_ERROR
} from "@/store/actions/bank-balance";

function initialState() {
  return {
    date: "",
    bank_accounts: [],
    transactions: [],
    pagination: {}
  };
}

const state = initialState();

const getters = {
  getBankAccounts: state => state.bank_accounts,
  getBankBalance: state => state.transactions,
  getBankBalanceDate: state => state.date,
  getBankBalancePagination: state => state.pagination
};

const actions = {
  [BANK_ACCOUNTS_GET]: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      commit("BANK_BALANCE_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get(`/user/bank-accounts`)
        .then(response => {
          commit("BANK_ACCOUNTS_GET_SUCCESS", response.data.data);

          resolve(response);
        })
        .catch(error => {
          commit("BANK_ACCOUNTS_GET_ERROR");

          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },

  [BANK_BALANCE_GET]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("BANK_BALANCE_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get(`/user/reports/bank-transactions/${params.id}`, {
          ...params.query
        })
        .then(response => {
          commit("BANK_BALANCE_GET_SUCCESS", response.data);

          resolve(response);
        })
        .catch(error => {
          commit("BANK_BALANCE_GET_ERROR");

          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  }
};

const mutations = {
  [BANK_BALANCE_REQUEST]: state => {
    state.status = "loading";
  },

  [BANK_ACCOUNTS_GET_SUCCESS]: (state, params) => {
    state.status = "success";
    state.bank_accounts = params.bank_accounts;
  },

  [BANK_ACCOUNTS_GET_ERROR]: state => {
    state.status = "error";
  },

  [BANK_BALANCE_RESET]: state => {
    state.transactions = [];
  },

  [BANK_BALANCE_GET_SUCCESS]: (state, params) => {
    let data = params.data;
    state.status = "success";
    state.date = data.date;
    state.transactions = data.transactions;
    state.pagination = params.paginate;
  },

  [BANK_BALANCE_GET_ERROR]: state => {
    state.status = "error";
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
