import axios from "axios";
import "@/store/actions/profile";
import {
  PROFILE_GET,
  PROFILE_GET_SUCCESS,
  PROFILE_GET_ERROR,
  EDIT_PROFILE_SUCCESS,
  EDIT_PROFILE_ERROR,
  EDIT_PROFILE
} from "@/store/actions/profile";

function initialState() {
  return {
    status: "",
    profile: {},
    message: "",
    errors: {}
  };
}

const state = initialState();

const getters = {
  profile: state => state.profile,
  userMessage: state => state.message
};

const actions = {
  [PROFILE_GET]: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get(`/user/auth/user`)
        .then(response => {
          let data = response.data;

          commit("PROFILE_GET_SUCCESS", { user: data.data.user });
          commit("AUTH_PROFILE_SUCCESS", { user: data.data.user });

          resolve(response);
        })
        .catch(error => {
          commit("PROFILE_GET_ERROR", { error: error });

          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },

  [EDIT_PROFILE]: ({ commit, dispatch }, formData) => {
    return new Promise((resolve, reject) => {
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .post(`/user/settings/profile`, formData, {
          headers: { "Content-Type": "multipart/form-data" }
        })
        .then(response => {
          commit("EDIT_PROFILE_SUCCESS", {
            message: response.data.data.message
          });

          resolve(response);
        })
        .catch(error => {
          dispatch("ALERT", {
            show: true,
            text: error.data.error.message
          });
          if (error.status === 422) {
            commit("EDIT_PROFILE_ERROR", {
              message: error.data.error.message,
              errors: error.data.error.errors
            });
            reject(error);
          }

          commit("EDIT_PROFILE_ERROR", {
            message: error.response.data.message
          });
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  }
};

const mutations = {
  [PROFILE_GET_SUCCESS]: (state, params) => {
    state.status = "success";
    state.profile = params.user;
  },
  [PROFILE_GET_ERROR]: (state, params) => {
    state.status = "error";
    state.message = params.message;
  },
  [EDIT_PROFILE_SUCCESS]: (state, params) => {
    state.status = "success";
    state.message = params.message;
  },
  [EDIT_PROFILE_ERROR]: (state, params) => {
    state.status = "error";
    state.message = params.message;
    state.errors = params.errors;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
