export const FL_RATIO_SETTINGS_REQUEST = "FL_RATIO_SETTINGS_REQUEST";
export const FL_RATIO_SETTINGS_GET = "FL_RATIO_SETTINGS_GET";
export const FL_RATIO_SETTINGS_GET_SUCCESS = "FL_RATIO_SETTINGS_GET_SUCCESS";
export const FL_RATIO_SETTINGS_GET_ERROR = "FL_RATIO_SETTINGS_GET_ERROR";
export const FL_RATIO_SETTINGS_UPDATE = "FL_RATIO_SETTINGS_UPDATE";
export const FL_RATIO_SETTINGS_UPDATE_SUCCESS =
  "FL_RATIO_SETTINGS_UPDATE_SUCCESS";
export const FL_RATIO_SETTINGS_UPDATE_ERROR = "FL_RATIO_SETTINGS_UPDATE_ERROR";
export const FL_RATIO_REPORT_GET = "FL_RATIO_REPORT_GET";
export const FL_RATIO_REPORT_GET_SUCCESS = "FL_RATIO_REPORT_GET_SUCCESS";
export const FL_RATIO_REPORT_GET_ERROR = "FL_RATIO_REPORT_GET_ERROR";
