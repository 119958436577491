import axios from "@/services/api";
import {
  FL_RATIO_SETTINGS_REQUEST,
  FL_RATIO_SETTINGS_GET,
  FL_RATIO_SETTINGS_GET_SUCCESS,
  FL_RATIO_SETTINGS_GET_ERROR,
  FL_RATIO_SETTINGS_UPDATE,
  FL_RATIO_SETTINGS_UPDATE_SUCCESS,
  FL_RATIO_SETTINGS_UPDATE_ERROR,
  FL_RATIO_REPORT_GET,
  FL_RATIO_REPORT_GET_SUCCESS,
  FL_RATIO_REPORT_GET_ERROR
} from "@/store/actions/fl-ratio";

function initialState() {
  return {
    f_ratio: "",
    l_ratio: "",
    report: {}
  };
}

const state = initialState();

const getters = {
  getFLRatioFoodRatio: state => state.f_ratio,
  getFLRatioLaborRatio: state => state.l_ratio,
  getFLRatioReport: state => state.report
};

const actions = {
  [FL_RATIO_SETTINGS_GET]: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      commit("FL_RATIO_SETTINGS_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get(`/user/settings/fl-ratios`)
        .then(response => {
          commit("FL_RATIO_SETTINGS_GET_SUCCESS", response.data.data);

          resolve(response);
        })
        .catch(error => {
          commit("FL_RATIO_SETTINGS_GET_ERROR");

          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },

  [FL_RATIO_SETTINGS_UPDATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("FL_RATIO_SETTINGS_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .put(`/user/settings/fl-ratios`, {
          ...params
        })
        .then(response => {
          commit("FL_RATIO_SETTINGS_UPDATE_SUCCESS", response.data.data);

          resolve(response);
        })
        .catch(error => {
          commit("FL_RATIO_SETTINGS_UPDATE_ERROR");

          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },

  [FL_RATIO_REPORT_GET]: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      commit("FL_RATIO_SETTINGS_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get(`/user/reports/fl-ratio`)
        .then(response => {
          commit("FL_RATIO_REPORT_GET_SUCCESS", response.data.data);

          resolve(response);
        })
        .catch(error => {
          commit("FL_RATIO_REPORT_GET_ERROR");

          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  }
};

const mutations = {
  [FL_RATIO_SETTINGS_REQUEST]: state => {
    state.status = "loading";
  },

  [FL_RATIO_SETTINGS_GET_SUCCESS]: (state, params) => {
    state.status = "success";
    state.f_ratio = params.fl_ratio.f_ratio;
    state.l_ratio = params.fl_ratio.l_ratio;
  },

  [FL_RATIO_SETTINGS_GET_ERROR]: state => {
    state.status = "error";
  },

  [FL_RATIO_SETTINGS_UPDATE_SUCCESS]: state => {
    state.status = "success";
  },

  [FL_RATIO_SETTINGS_UPDATE_ERROR]: state => {
    state.status = "error";
  },

  [FL_RATIO_REPORT_GET_SUCCESS]: (state, params) => {
    state.status = "success";
    state.report = params;
  },

  [FL_RATIO_REPORT_GET_ERROR]: state => {
    state.status = "error";
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
