<template>
  <v-dialog
    persistent
    :value="visible"
    @click:outside="hideModal"
    @keydown.esc="hideModal"
  >
    <v-card>
      <v-card-title class="headline">
        {{ getModalTitle }}
      </v-card-title>

      <v-card-text>
        <component :is="component"></component>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="hideModal">
          {{ $t("close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from "vue";
import { mapMutations, mapGetters, mapState } from "vuex";
export default {
  name: "ModalDialog",
  computed: {
    ...mapGetters(["getModalTitle", "getModalComponent"]),
    ...mapState({
      visible: "modalVisible",
      modalComponent: "modalComponent"
    })
  },
  data() {
    return {
      component: null
    };
  },
  watch: {
    getModalComponent: {
      handler(componentName) {
        if (!componentName) return;

        Vue.component(componentName, () =>
          import(`@/components/layout/modals/${componentName}`)
        );

        this.component = componentName;
      },
      immediate: true
    }
  },
  methods: {
    ...mapMutations(["hideModal"])
  }
};
</script>

<style scoped></style>
