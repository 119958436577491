<template>
  <v-app-bar color="primary" dark dense>
    <template v-if="$route.name === 'home'">
      <v-toolbar-title>
        <router-link to="/">
          <v-img
            width="175"
            :src="require('@/assets/images/cp-app-logo.svg')"
          />
        </router-link>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <div class="title--text text-heading-2">
        {{ user.last_name }}
      </div>
      <div class="title--text title-honorofics">
        さん
      </div>
    </template>

    <template v-else>
      <v-app-bar-nav-icon @click="$router.go(-1)">
        <v-icon size="32" color="white">$backButton</v-icon>
      </v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-toolbar-title class="title--text font-weight-medium text-heading-5">
        {{ $t(`title_${this.$route.name}`) }}
      </v-toolbar-title>

      <v-spacer></v-spacer>
    </template>
    <v-app-bar-nav-icon :ripple="false" disabled>
      <v-icon></v-icon>
    </v-app-bar-nav-icon>
  </v-app-bar>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ApplicationBar",
  computed: {
    ...mapGetters(["user"])
  },
  data() {
    return {
      group: null,
      userName: "日本料理 楮山"
    };
  }
};
</script>

<style scoped>
.title--text {
  color: #c7d8fb;
}
.title-honorofics {
  font-size: 8px;
}
</style>
