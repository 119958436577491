export const GOALS_REQUEST = "GOALS_REQUEST";
export const GOALS_GET = "GOALS_GET";
export const GOALS_GET_SUCCESS = "GOALS_GET_SUCCESS";
export const GOALS_GET_ERROR = "GOALS_GET_ERROR";

export const GOALS_UPDATE = "GOALS_UPDATE";
export const GOALS_UPDATE_SUCCESS = "GOALS_UPDATE_SUCCESS";
export const GOALS_UPDATE_ERROR = "GOALS_UPDATE_ERROR";

export const GOALS_REPORT_GET = "GOALS_REPORT_GET";
export const GOALS_REPORT_GET_SUCCESS = "GOALS_REPORT_GET_SUCCESS";
export const GOALS_REPORT_GET_ERROR = "GOALS_REPORT_GET_ERROR";
