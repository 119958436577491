export const LINK_POST_REQUEST = "LINK_POST_REQUEST";
export const LINK_POST_GET = "LINK_POST_GET";
export const LINK_POST_GET_SUCCESS = "LINK_POST_GET_SUCCESS";
export const LINK_POST_GET_ERROR = "LINK_POST_GET_ERROR";

export const LINK_POST_GET_DETAIL = "LINK_POST_GET_DETAIL";
export const LINK_POST_GET_DETAIL_SUCCESS = "LINK_POST_GET_DETAIL_SUCCESS";
export const LINK_POST_GET_DETAIL_ERROR = "LINK_POST_GET_DETAIL_ERROR";

export const LINK_POST_CLICK_UPDATE = "LINK_POST_CLICK_UPDATE";
export const LINK_POST_CLICK_UPDATE_SUCCESS = "LINK_POST_CLICK_UPDATE_SUCCESS";
export const LINK_POST_CLICK_UPDATE_ERROR = "LINK_POST_CLICK_UPDATE_ERROR";
