import axios from "@/services/api";
import {
  GOALS_GET,
  GOALS_GET_SUCCESS,
  GOALS_GET_ERROR,
  GOALS_REQUEST,
  GOALS_UPDATE,
  GOALS_UPDATE_SUCCESS,
  GOALS_UPDATE_ERROR,
  GOALS_REPORT_GET,
  GOALS_REPORT_GET_SUCCESS,
  GOALS_REPORT_GET_ERROR
} from "@/store/actions/goals";
import store from "@/store";

function initialState() {
  return {
    business_year: {},
    previous_term: {},
    goals: [],
    report: {}
  };
}

const state = initialState();

const getters = {
  getGoalBusinessYear: state => state.business_year,
  getGoalPreviousTerm: state => state.previous_term,
  getGoals: state => state.goals,
  getGoalReport: state => state.report
};

const actions = {
  [GOALS_GET]: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      commit("GOALS_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get(`/user/settings/goals`)
        .then(response => {
          commit("GOALS_GET_SUCCESS", response.data.data);

          resolve(response);
        })
        .catch(error => {
          commit("GOALS_GET_ERROR");

          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },

  [GOALS_UPDATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("GOALS_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .put(`/user/settings/goals`, {
          goals: params.goals
        })
        .then(response => {
          commit("GOALS_UPDATE_SUCCESS", response.data.data);

          resolve(response);
        })
        .catch(error => {
          commit("GOALS_UPDATE_ERROR");

          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },

  [GOALS_REPORT_GET]: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      commit("GOALS_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get(`/user/reports/goals`)
        .then(response => {
          commit("GOALS_REPORT_GET_SUCCESS", response.data.data);

          resolve(response);
        })
        .catch(error => {
          commit("GOALS_REPORT_GET_ERROR");

          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  }
};

const mutations = {
  [GOALS_REQUEST]: state => {
    state.status = "loading";
  },

  [GOALS_GET_SUCCESS]: (state, params) => {
    state.status = "success";
    state.business_year = params.business_year;
    state.previous_term = params.previous_term;
    state.goals = params.goals;
  },

  [GOALS_GET_ERROR]: state => {
    state.status = "error";
  },

  [GOALS_UPDATE_SUCCESS]: state => {
    state.status = "success";
  },

  [GOALS_UPDATE_ERROR]: state => {
    state.status = "error";
    store.commit("showAlert", {
      text: "There was an error while updating the goals"
    });
  },

  [GOALS_REPORT_GET_SUCCESS]: (state, params) => {
    state.status = "success";
    state.report = params;
  },

  [GOALS_REPORT_GET_ERROR]: state => {
    state.status = "error";
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
